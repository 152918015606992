<template>
  <div>
    <section class="white">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <h2
              :class="[$vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline']"
              class="mb-4"
            >
              Χορήγηση ή Αντικατάσταση Άδειας Κυκλοφορίας
            </h2>
            <br>
            <v-btn
              color="success"
              class="px-5"
              large
              :to="`/contact-me`"
            >
              Επικοινωνηστε τωρα
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="5"
            offset-md="1"
          >
            <v-card>
              <v-img src="/static/sideServices/Άδειες Κυκλοφορίας.jpg" />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="grey lighten-3">
      <v-container>
        <p class="display-1">
          ΑΠΑΙΤΟΥΜΕΝΑ ΔΙΚΑΙΟΛΟΓΗΤΙΚΑ (για χορήγηση)<br><br>
        </p><p class="subtitle-1">
          <ul>
            <li>Αίτηση – Υπεύθυνη Δήλωση για το μόνιμο της κατοικίας.</li>
            <li>Επικυρωμένο φωτοαντίγραφο Δ.Τ.Ε. από το ΚΤΕΟ (μόνο εφόσον το αυτοκίνητο είναι μεταχειρισμένο και κυκλοφορεί για πρώτη φορά στην Ελλάδα</li>
            <li>Αστυνομική ταυτότητα</li>
            <li>Επικυρωμένο φωτοαντίγραφο ξένης άδειας κυκλοφορίας για μεταχειρισμένο αυτοκίνητο. <br> Εφόσον πρόκειται για καινούργιο αυτοκίνητο απαιτείται έγκριση τύπου και πιστοποιητικό συμμόρφωσης</li>
            <li>Πιστοποιητικά Τελωνείων ή κατασκευαστή</li>
            <li>Α.Φ.Μ. αγοραστή</li>
            <li>Τιμολόγιο Πώλησης, σε περίπτωση που το υπό ταξινόμηση όχημα υπάγεται στο πεδίο εφαρμογής Φ.Π.Α.</li>
            <br>
          </ul>
        </p>
      </v-container>
    </section>

    <section class="white">
      <v-container>
        <p class="display-1">
          ΑΠΑΙΤΟΥΜΕΝΑ ΔΙΚΑΙΟΛΟΓΗΤΙΚΑ (για Αντικατάσταση)<br><br>
        </p><p class="subtitle-1">
          <ul>
            <li>Αίτηση – Υπεύθυνη δήλωση Ν. 1599/ 86 με κείμενο: ” έχω χάσει – μου έχει κλαπεί η άδεια του με αριθμό κυκλοφορίας _______ Ε.Ι.Χ ή Δ.Ι.Χ και αυτή δεν παρακρατείται από καμιά Δημόσια ή Αστυνομική Αρχή “. (στην περίπτωση απώλειας – κλοπής)</li>
            <li>Η φθαρμένη άδεια κυκλοφορίας (στην περίπτωση φθοράς)</li>
            <br>
          </ul>
        </p>
      </v-container>
    </section>

    <section class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col
            v-for="pdf in pdfs"
            :key="pdf"
            cols="6"
            md="6"
          >
            <div class="text-center">
              <div
                class="headline font-weight-bold mb-3"
                v-text="pdf"
              />
              <v-btn
                color="success"
                class="px-5"
                large
              >
                ΛΗΨΗ
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
  export default {
    data: () => ({
      pdfs: [
        'Αντικατάσταση Άδειας Κυκλοφορίας Αυτοκινήτου ή Μοτοσυκλέτας Λόγω Απώλειας – Φθοράς – Κλοπής',
        'Χορήγηση Άδειας Κυκλοφορίας Ε.Ι.Χ – Δ.Ι.Χ'
      ]
    })
  }
</script>
